// Imports
// ------
import React, { useEffect } from 'react';
import Seo from '@lay/Seo';
import LegalContent from '@parts/Legal';
import Newsletter from '@parts/Newsletter';
import Footer from '@parts/Footer';
import PageTransition from '@parts/PageTransition';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Component
// ------
function Test({ transitionStatus, entry, exit, data }) {
    const ts = transitionStatus;
    const bp = useBreakpoint();

    useEffect(() => {
        const timer = setTimeout(
            () => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            },
            bp.large ? 500 : 1000
        );

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <>
            <Seo data={data.globalSeo.seo} backupData={data.globalSeo.seo} />

            <PageTransition status={ts} entry={entry} exit={exit}>
                <LegalContent data={data.legal.sections} />
                <Newsletter />
                <Footer />
            </PageTransition>
        </>
    );
}

export default Test;

// GraphQL
// ------
export const query = graphql`
	query MaterialLibraryLegal {
		globalSeo: datoCmsOptionsGlobal {
			seo: seoMeta {
				title
				image {
					url
				}
				desc: description
				card: twitterCard
			}
		}
		legal: datoCmsMaterialLibraryLegal {
			sections: legalSections {
				title
				content
			}
		}
	}
`;
